import {
  IDroneService,
  IFirebaseService,
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { env } from "../env";
import { IDroneImage, IDronePath } from "../interfaces/map.interfaces";

export default class DroneService
  extends AbstractService
  implements IDroneService
{
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getMyData(projectId: string): Promise<{ data: IDronePath[] }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/my_data`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }

  public async getImages(projectId: string): Promise<{ data: IDroneImage[] }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/images`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }

  public async getImageUrl(key: string): Promise<{ data: string }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/getImageUrl`;
    return await this.axiosInstance.get(url, { params: { key } });
  }
}
