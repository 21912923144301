import React, {useCallback, useEffect, useState} from "react";
import Map from '../Map/deck.gl.map';
import {ILocation, IPipelineData, IPipelineProperties, IServerEvent} from "../../interfaces/map.interfaces";
import MapLogic from "../../logic/map.logic";
import MapDataContext from './map.data.context';
import {Moment} from "moment-timezone/moment-timezone";
import {FeatureCollection, LineString, MultiLineString} from "geojson";
import {IEventService} from "../../interfaces/services.interfaces";
import { IMapDataContext } from "../../interfaces/demo.interfaces";

interface IMapDataProps {
    pipelineDataGeoJson: FeatureCollection<LineString | MultiLineString, IPipelineProperties>,
    locationsAndEventsData: ILocation[],
    eventService: IEventService,
}

export default function GenericMapData(props: IMapDataProps) {
    const [ locationsData, setLocationsData ] = useState<ILocation[]>([]);
    const [ pipelineData, setPipelineData ] = useState<IPipelineData>();
    const [ initialMapView, setInitialMapView] = useState<{zoom: number, longitude: number, latitude: number}>();
    const [ pipelineLastCheckedUtc, setPipelineLastCheckedUtc ] = useState<Moment>();

    useEffect(() => {
      if (props.locationsAndEventsData) {
        setLocationsData(props.locationsAndEventsData);
      }
    }, [props.locationsAndEventsData]);

    useEffect(() => {
      if (props.pipelineDataGeoJson && props.pipelineDataGeoJson.features) {
        setPipelineData(props.pipelineDataGeoJson);

        // if we are supposed to show a particular pipeline, do so:
        let args = window.location.search.substr(1).split("&");
        let doShowPipeline = null;
        for (let s = 0; s < args.length; s++) {
          let argPair = args[s].split("=");
          if (argPair[0] === "pipeline") {
            doShowPipeline = argPair[1];
          }
        }
        let showedPipeline = false;
        if (doShowPipeline) {
          for (let i = 0; i < props.pipelineDataGeoJson.features.length; i++) {
            if (doShowPipeline === props.pipelineDataGeoJson.features[i].properties.id) {
              let singlePipelineData = {
                type: "FeatureCollection",
                features: [props.pipelineDataGeoJson.features[i]],
              };
              setInitialMapView(MapLogic.fitMapToPipelineBoundingBox(singlePipelineData as any));
              showedPipeline = true;
              break;
            }
          }
        }

        // otherwise, zoom and center so that all pipelines are shown:
        if (!showedPipeline) {
          setInitialMapView(MapLogic.fitMapToPipelineBoundingBox(props.pipelineDataGeoJson));
        }

        setPipelineLastCheckedUtc(MapLogic.extractPipelineLastChecked(props.pipelineDataGeoJson));
      }
    }, [props.pipelineDataGeoJson]);

  const updateEvent = useCallback((previousEvent: IServerEvent, newType:string, newSubType:string, newState: string, newComment: string, author: string) => {    
    for (let i = 0; i < locationsData.length; i++) {
      for (let j = 0; j < locationsData[i].events.length; j++) {
        if (locationsData[i].events[j].properties.id === previousEvent.properties.id) {
          locationsData[i].events[j].properties.state = newState;
          if(newType != undefined) {locationsData[i].events[j].properties.subType = newType;}
        }
      }
    }
        setLocationsData(locationsData);
        props.eventService.updateEvent(previousEvent, newType, newSubType, newState, newComment, author);
    }, [locationsData, props])
	
  const addHeliEvent = useCallback(
    (event: IServerEvent) => {
      return props.eventService.addHeliEvent(event);
		},
		[locationsData]
	);

    const state:IMapDataContext = {
      updateEvent,
	    addHeliEvent
    }


    // if we don't have any pipeline data from the backend yet,
    // don't even start to render the map at all
    if (pipelineData === undefined) {
      return (null);
    }

    return (
    <MapDataContext.Provider value={state}>
            <Map
                pipelineData={pipelineData}
                locationsData={locationsData}
                initialView={initialMapView}
                pipelineLastCheckedUtc={pipelineLastCheckedUtc}
            />
        </MapDataContext.Provider>
    );
}
