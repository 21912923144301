import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import AppContext from "../../context/app.context";
import appContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import Loader from "../Animations/Loader";
import { ICompareImages } from "./deck.gl.map";
import { env } from './../../env';
import Style from "./Map.module.css";
import {
	ZoomInOutlined, ZoomOutOutlined
} from '@ant-design/icons';
import { Button} from 'antd';
function CompareImages({
	images,
	closeModal,
}: {
	images: ICompareImages;
	closeModal: Function;
}) {
	const appContext: IAppContext = useContext(AppContext);
	const shouldShowModal: boolean = !!images.eventAfter || !!images.eventBefore;
	const [tokenData, setTokenData] = useState({ forceRefresh: true, token: "" });
	const [modalSize, setModalSize] = useState("20%")
	useEffect(() => {
		appContext?.services?.firebaseService?.auth?.currentUser
			?.getIdToken()
			.then((value) => setTokenData({ forceRefresh: false, token: value }));
	}, []);
	if (!shouldShowModal) {
		return null;
	}
	// console.log(images)

	const serverUrl = `${env.REACT_APP_SERVER_URL}`;
	// console.log(serverUrl)
    // console.log("comparing images")
	return (
		<Modal
			centered
			getContainer={"#root"}
			onCancel={() => {
				closeModal()
				setModalSize("20%")
			}}
			visible={shouldShowModal}
			footer={null}
			width={modalSize}
			>
			<ReactCompareImage
				leftImage={
					serverUrl +
					"/image/retrieve?eventId=" +
					images.eventBefore?.id +
					"&token=" +
					tokenData.token
				}
				leftImageAlt="Before"
				leftImageLabel={`${images.eventBefore?.date.slice(0, 10)}`}
				rightImage={
					serverUrl +
					"/image/retrieve?eventId=" +
					images.eventAfter?.id +
					"&token=" +
					tokenData.token
				}
				rightImageAlt="After"
				rightImageLabel={`${images.eventAfter?.date.slice(0, 10)}`}
				skeleton={<Loader />}
				rightImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
				leftImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
				vertical={false}
				handleSize={images.sliderController ? 40 : null}
				sliderPositionPercentage={images.sliderController ? 0.5 : 0}
				sliderLineWidth={images.sliderController}
			/>
			<Button
                className={Style.enlargeCompareImage }
				onClick={() => {
					setModalSize("50%")
				}}
				disabled = {modalSize === "50%"}
              >
                <ZoomInOutlined />
            </Button>
			<Button
                className={Style.minmizeComapreImage }
				onClick={() => {
					setModalSize("20%")
				}}
				disabled = {modalSize === "20%"}
              >
                <ZoomOutOutlined />
            </Button>
		</Modal>
	);
}

export default CompareImages;
