import { Button, Dropdown, Icon, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import {
  IEvent,
  IEventProperties,
  ILocation,
} from "../../interfaces/map.interfaces";
import {
  consolidateEventState,
  getGMapLink,
  showDate,
  translateEventState,
} from "../../utils/event.utils";
import { exportEventToJsonWithMessage } from "../../utils/export.utils";
import MapTooltip from "./map.tooltip";
import { getImageComparisonEvents, getUpdatesImageComparisonEvents } from "./map.utils";
import EventDetails from "./EventDetails";
import UpdateEventForm from "./UpdateEventForm";

function EventTooltip({
  eventObject,
  eventsData,
  refreshEvents,
  zoomIn,
  openImageCompare,
  setVideoUrl,
  setShowVideoModal,
}: {
  eventObject: IEvent;
  eventsData: ILocation[];
  refreshEvents: Function;
  zoomIn: Function;
  openImageCompare: Function;
  setVideoUrl: (v: string) => void;
  showVideoModal: boolean;
  setShowVideoModal: (v: boolean) => void;
}) {
  const appContext: IAppContext = useContext(AppContext);
  const [language, setLanguage] = useState<string>();
  const [viewEventHistory, setViewEventHistory] = useState("none");
  const [visible, setVisible] = useState<boolean>(false);
  const [subTypeEnabled, setSubTypeEnabled] = useState<boolean>();

  const showVideo = async () => {
    const preSignedUrl = await appContext.services.videoService.getSignedUrl(
      eventObject.properties.image.replace("png", "MOV")
    );
    if (preSignedUrl) {
      setVideoUrl(preSignedUrl);
      setShowVideoModal(true);
    } else {
      setVideoUrl("");
      setShowVideoModal(false);
    }
  };

  useEffect(() => {
    appContext.services.userService.getMyData().then((data) => {
      setLanguage(data.data.language);
    });
    appContext.services.projectService.getMyData().then((data) => {
      setSubTypeEnabled(data.data[0].subTypeEnabled);
    });
  }, []);

  if (!eventObject || eventObject?.type !== "Point") return null;

  const properties = eventObject.properties as IEventProperties;

  // console.log(properties)

  const eventId = properties.id;

  let location = null;
  let prevEvents = [];
  let appendEvents = false;

  for (let i = 0; i < eventsData.length; i++) {
    if (properties.locationId === eventsData[i].id) {
      location = eventsData[i];
      let otherEvents = location.events;
      for (let j = 0; j < otherEvents.length; j++) {
        if (appendEvents) {
          prevEvents.push(otherEvents[j]);
        }
        if (otherEvents[j].properties.id === eventId) {
          appendEvents = true;
        }
      }
      break;
    }
  }

  let prevEventsHtml = [];
  for (let i = 0; i < prevEvents.length; i++) {
    let ev = prevEvents[i];
    let eventDate = ev?.properties?.date;
    let eventID = ev?.properties?.id
    let eventState = consolidateEventState(ev?.properties?.state);
    let eventStateLang = translateEventState(eventState, language);

    let el = (
      <div key={i + "_div"}>
        <span>
          <b>{language === "de" ? "Datum" : "Date"}:</b> {showDate(eventDate)}
        </span>
        <br />
        <span>
          <b>{language === "de" ? "Status" : "State"}:</b> {eventStateLang}
        </span>
        <br />
        <span>
          <b>{language === "de" ? "Art" : "Type"}:</b> {ev?.properties?.type}
        </span>
        <br />
        <span>
          <b>{language === "de" ? "Kommentar" : "Comment"}:</b>{" "}
          {ev?.properties?.comment}
        </span>
        <span>
          <Button style={{marginTop : "-40px", float: "right"}} disabled ={!ev.properties.image} onClick={() => {
                let imageCompare = getUpdatesImageComparisonEvents(location, eventDate, eventID);
                
                openImageCompare(imageCompare);
                zoomIn(eventObject as IEvent);
          }}>{language === "de" ? "anzeigen" : "View"}</Button>
        </span>
      </div>
    );
    prevEventsHtml.push(el);
    el = <br key={i + "_br"} />;
    prevEventsHtml.push(el);
  }
  if (prevEvents.length < 1) {
    let el = (
      <div key={"0_div"}>
        <span> 
          <i>
            {language === "de"
              ? "Es gibt keine früheren Updates."
              : "There are no earlier updates."}
          </i>
        </span>
      </div>
    );
    prevEventsHtml.push(el);
    el = <br key={"0_br"} />;
    prevEventsHtml.push(el);
  }

  let eventMenu = (
    <Menu style={{ textAlign: "center" }}>
      <Menu.Item
        key="1"
        onClick={() => {
          exportEventToJsonWithMessage(eventObject as IEvent);
        }}
      >
        {language === "de" ? "Exportieren" : "Export"}{" "}
        <Icon type="export" style={{ margin: "0px" }} />
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setVisible(true);
        }}
      >
        {language === "de" ? "Bearbeiten" : "Edit"} <Icon type="edit" />
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          window.open(getGMapLink(eventObject.coordinates));
        }}
      >
        {language === "de"
          ? "Navigieren auf Google Maps "
          : "Navigate on Google Maps "}
        <Icon type="pushpin" />
      </Menu.Item>
    </Menu>
  );

  let buttons = [
    <Button
      key="zoom"
      size="small"
      style={{ marginRight: "5px" }}
      onClick={() => {
        zoomIn(eventObject as IEvent);
      }}
    >
      {language === "de" ? "Zoom" : "Zoom"}
    </Button>,
    <Button key="view" size="small" style={{ marginRight: "5px" }}>
      <Dropdown
        overlay={
          <Menu style={{ textAlign: "center" }}>
            <Menu.Item
              key="image"
              onClick={() => {
                console.log(`Location : ${location}`)
                let imageCompare = getImageComparisonEvents(location);
                openImageCompare(imageCompare);
                zoomIn(eventObject as IEvent);
              }}
              disabled={
                !!properties.CH4 ||
                !!properties.rate ||
                !getImageComparisonEvents(location)?.eventAfter
              }
            >
              {/* Image */}
              {language === "de" ? "Bild" : "Image"}
              <Icon type="photo" style={{ margin: "0px" }} />
            </Menu.Item>
            <Menu.Item
              key="video"
              onClick={showVideo}
              disabled={!eventObject.properties?.image.includes("png")}
            >
              {/* Video */}
              {language === "de" ? "Video" : "Video"}
              <Icon type="video" style={{ margin: "0px" }} />
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {language === "de" ? "Anzeigen" : "View"} <Icon type="caret-down" />
        </a>
      </Dropdown>
    </Button>,
    <Button
      key="history"
      size="small"
      style={{ marginRight: "5px" }}
      onClick={() => {
        const eventHistory = document.getElementById("eventHistory");
        if (eventHistory.style.display === "none") {
          eventHistory.style.display = "block";
          setViewEventHistory("block");
        } else {
          eventHistory.style.display = "none";
          setViewEventHistory("none");
        }
      }}
    >
      {language === "de" ? "Siehe Verlauf" : "View History"}{" "}
      {viewEventHistory === "none" ? (
        <Icon type="caret-down" />
      ) : (
        <Icon type="caret-up" />
      )}
    </Button>,
    <Button key="actions" size="small" style={{ marginRight: "5px" }}>
      <Dropdown
        overlay={eventMenu}
        trigger={["click"]}
        placement="bottomCenter"
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {language === "de" ? "Aktionen" : "Actions"}{" "}
          <Icon type="caret-down" />
        </a>
      </Dropdown>
    </Button>,
  ];

  return (
    <MapTooltip position={{ x: 0, y: 0 }}>
      <EventDetails
        properties={properties}
        subTypeEnabled={subTypeEnabled}
        language={language}
        coordinates={eventObject.coordinates}
        buttons={buttons}
      />
      <span
        id="eventHistory"
        style={{ display: "none", marginBottom: "-50px" }}
      >
        <br />
        <span>
          <b>{language === "de" ? "Frühere Updates" : "Previous updates"}:</b>
        </span>
        <br />
        {prevEventsHtml}
      </span>
      <br />
      <br />
      <UpdateEventForm
        event={eventObject as IEvent}
        visible={visible}
        setVisible={setVisible}
        refreshEvents={refreshEvents}
      />
    </MapTooltip>
  );
}

export default EventTooltip;
