import { Form, Input, Modal, Radio, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useContext, useEffect, useState } from "react";
import { BUTTON_PROPS } from "../../constants/general";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import { IProject, IServerEvent, IUser } from "../../interfaces/map.interfaces";
import MapDataContext from "../MapData/map.data.context";
import { IDeckGlInfoObject } from "./deck.gl.map";
import UploadImage from "./UploadImage";

function AddNewEvent({
  event,
  enabled,
  setEnableHeli,
  refreshEvents,
}: {
  event: IDeckGlInfoObject;
  enabled: boolean;
  setEnableHeli : Function
  refreshEvents: Function;
}) {
  const appContext: IAppContext = useContext(AppContext);
  const mapDataContext = useContext(MapDataContext);

  const [user, setUser] = useState<IUser>();
  const [project, setProject] = useState<IProject>();
  const [visible, setVisible] = useState<boolean>(enabled);
  const [initialClick, setInitialClick] = useState(true);
  const [count, setCount] = useState(0);

  const [newEventState, setNewEventState] = useState<string>();
  const [newTypeState, setNewTypeState] = useState<string>();
  const [detectedBy, setDetectedBy] = useState<string>();
  const [newSubTypeState, setNewSubTypeState] = useState<string>();
  const [newEventComment, setNewEventComment] = useState<string>("");
  const [newEventAuthor, setNewEventAuthor] = useState<string>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<UploadFile>(null);
  const { Option } = Select;

  const language = user?.language;

  useEffect(() => {
    appContext.services.userService.getMyData().then((data) => {
      setUser(data.data);
      setNewEventAuthor(data.data?.email || "");
    });
    appContext.services.projectService.getMyData().then((data) => {
      setProject(data.data[0]);
    });
  }, []);

  useEffect(() => {
    if (!enabled) {
      setInitialClick(true);
      setCount(0);
    }
  }, [enabled]);

  useEffect(() => {
    if (!event) return;
    setVisible(true);
    enabled && setCount(count + 1);
  }, [event]);

  useEffect(() => {
    if (count > 1) {
      setInitialClick(false);
    }
  }, [count]);

  useEffect(() => {
    if (!visible) return;
    setNewSubTypeState("");
    setNewTypeState("");
    setNewEventState("");
  }, [event, visible]);

  if (event.object) return null;

  const DetectedByOptions = ["Helicopter", "Drone", "Satellite", "Others"]

  const getDetectedByOptions = () => {
    let optionsArr = [];
    if (DetectedByOptions) {
      for (const option of DetectedByOptions) {
        optionsArr.push(
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      }
    }

    return optionsArr;
  }

  const getOptions = (options: string[]) => {
    let optionsArr = [];
    if (options) {
      for (const option of options) {
        optionsArr.push(
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      }
    }

    return optionsArr;
  };

  const onAddEvent = (event: IServerEvent) => {
    if (!mapDataContext) {
      return;
    }

    mapDataContext.addHeliEvent(event).then((data) => {
      appContext.services.imageService.uploadImage(
        imageFile?.originFileObj as File,
        data?.data?.properties?.id + ".png"
      );
    });
    setTimeout(() => {
      refreshEvents();
    }, 1000);
  };

  const handleOk = (event: IDeckGlInfoObject) => {
    setConfirmLoading(true);
    setTimeout(() => {
      const newEvent: IServerEvent = {
        properties: {
          id: "",
          locationId: "",
          type: newTypeState,
          sourcetype: "heli",
          subType: newSubTypeState,
          comment: newEventComment,
          state: newEventState,
          date: "",
          author: newEventAuthor,
          projectId: project?.id,
          image: imageFile ? "_" : "",
          detectedBy: detectedBy
        },
        geometry: {
          type: "Point",
          coordinates: event.coordinate,
        },
      };

      onAddEvent(newEvent);

      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
    setNewSubTypeState("");
    setNewTypeState("");
    setDetectedBy("")
    setNewEventState("");
    setNewEventComment("");
    setImageFile(null);
    setEnableHeli(false)
  };

  const handleCancel = () => {
    setNewSubTypeState("");
    setNewTypeState("");
    setDetectedBy("")
    setNewEventState("");
    setNewEventComment("");
    setVisible(false);
    setImageFile(null);
    setEnableHeli(false)
  };

  return (
    <Modal
      title={language === "de" ? "Bearbeiten" : "Edit"}
      className="MapDeckComment"
      centered
      okText={"Submit"}
      visible={visible && enabled && !initialClick}
      onOk={() => {
        handleOk(event);
      }}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{
        type: "primary",
        shape: "round",
        size: "small",
        disabled:
          !newEventState &&
          !newSubTypeState &&
          !newTypeState &&
          !newEventComment,
      }}
      cancelButtonProps={{
        ...BUTTON_PROPS,
        type: "danger",
        shape: "round",
        size: "small",
      }}
    >
      <Form onClick={(e) => e.stopPropagation()}>
        <Form.Item label={language === "de" ? "Status" : "State"}>
          <Radio.Group
            defaultValue={newEventState}
            onChange={(e) => setNewEventState(e.target.value)}
            value={newEventState}
          >
            <Radio.Button value={"active"}>
              {language === "de" ? "Aktiv" : "Active"}
            </Radio.Button>
            <Radio.Button value={"only report change"}>
              {language === "de" ? "Nur Änderung Melden" : "Only Report Change"}
            </Radio.Button>
            <Radio.Button value={"archived"}>
              {language === "de" ? "Archiv" : "Archive"}
            </Radio.Button>
            <Radio.Button value={"do not report"}>
              {language === "de" ? "Nicht Mehr Melden" : "Do Not Report"}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={language === "de" ? "Typ" : "Type"}>
          <Select
            style={{ width: 200 }}
            defaultValue={newTypeState}
            onChange={(value) => setNewTypeState(value)}
            value={newTypeState}
          >
            {getOptions(project?.types)}
          </Select>
        </Form.Item>
        <Form.Item label={language === "de" ? "Typ" : "Detected By"}>
          <Select
            style={{ width: 200 }}
            defaultValue={detectedBy}
            onChange={(value) => setDetectedBy(value)}
            value={detectedBy}
          >
            {getDetectedByOptions()}
          </Select>
        </Form.Item>
        <span hidden={!project?.subTypeEnabled}>
          <Form.Item label={language === "de" ? "Sub Typ" : "SubType"}>
            <Select
              style={{ width: 200 }}
              defaultValue={newSubTypeState}
              onChange={(value) => setNewSubTypeState(value)}
              value={newSubTypeState}
            >
              {getOptions(project?.subTypes)}
            </Select>
          </Form.Item>
        </span>
        <Form.Item label={language === "de" ? "Kommentar" : "Comment"}>
          <Input
            placeholder={
              language === "de"
                ? "Schreiben Sie Ihren Kommentar..."
                : "Write your comment..."
            }
            value={newEventComment}
            onChange={(e) => setNewEventComment(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={language === "de" ? "Bearbeiter" : "Editor"}>
          <Input
            placeholder={language === "de" ? "Name des Autors" : "Author Name"}
            value={newEventAuthor}
            onChange={(e) => setNewEventAuthor(e.target.value)}
          />
        </Form.Item>
      </Form>
      <UploadImage
        setFile={setImageFile}
        file={imageFile?.originFileObj as File}
      />
    </Modal>
  );
}

export default AddNewEvent;
