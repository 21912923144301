import React, { Component } from "react";
import withAuthorization from "../HOC/with.authorization";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import GenericMapData from "./GenericMapData";
import { FeatureCollection, LineString, MultiLineString } from "geojson";
import {
  ILocation,
  IPipelineProperties,
} from "../../interfaces/map.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import {IEventService, IPipelineService} from "../../interfaces/services.interfaces";
import AppContext from "../../context/app.context";


interface IProps {
}

interface IState {
  locationsAndEventsData: ILocation[],
  pipelineDataGeoJson: FeatureCollection<LineString | MultiLineString, IPipelineProperties>,
}

class MapData extends Component<IProps, IState> {

  static contextType = AppContext;

  private eventService: IEventService;

  private pipelineService: IPipelineService;

  constructor(props: any, context: any) {

    super(props);

    this.state = {
      locationsAndEventsData: [],
      pipelineDataGeoJson: null,
    };

    this.eventService = context.services.eventService;

    this.eventService.getMyData().then((data: any) => {
      console.log("got event data:");
      console.log(data);
      this.setState({
        locationsAndEventsData: data?.data,
      });
      if (!(data?.data?.length)) {
        let outerThis = this;
        window.setTimeout(function() {
          outerThis.eventService.getMyData().then((newData: any) => {
            console.log("got new event data on second attempt:");
            console.log(newData);
            outerThis.setState({
              locationsAndEventsData: newData?.data,
            });
          }).catch((error: any) => {
            console.log("server error upon getting event data, second attempt!");
            console.log(error);
            (window as any).encounteredError = true;
          });
        }, 2500);
      }
    }).catch((error: any) => {
      console.log("server error upon getting event data!");
      console.log(error);
      (window as any).encounteredError = true;
    });

    this.pipelineService = context.services.pipelineService;

    this.pipelineService.getMyData().then((data: any) => {
      console.log("got pipeline data:");
      console.log(data);
      this.setState({
        pipelineDataGeoJson: data?.data,
      });
      if (!(data?.data?.features?.length)) {
        let outerThis = this;
        window.setTimeout(function() {
          outerThis.pipelineService.getMyData().then((newData: any) => {
            console.log("got new pipeline data on second attempt:");
            console.log(newData);
            outerThis.setState({
              pipelineDataGeoJson: newData?.data,
            });
          }).catch((error: any) => {
            console.log("server error upon getting pipeline data, second attempt!");
            console.log(error);
            (window as any).encounteredError = true;
          });
        }, 2500);
      }
    }).catch((error: any) => {
      console.log("server error upon getting pipeline data!");
      console.log(error);
      (window as any).encounteredError = true;
    });
  }

  render() {

      return (
        <GenericMapData
          locationsAndEventsData={
            this.state.locationsAndEventsData
          }
          pipelineDataGeoJson={
            this.state.pipelineDataGeoJson
          }
          eventService={
            this.eventService
          }
        />
      );
  }
}

export const MapLink = () => (
  <p>
    Back to <Link to={ROUTES.MAP.route}>{ROUTES.MAP.label}</Link>
  </p>
);

const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(MapData);
