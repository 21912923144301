import { IPipelineData } from "../interfaces/map.interfaces";
import moment from "moment-timezone";
import { Moment } from "moment-timezone/moment-timezone";
import { DEFAULT_MAP_VIEW_PROPS } from "../constants/map.consts";
import { fitBounds } from "@math.gl/web-mercator";
import { LngLatBounds, LngLat } from "mapbox-gl";
import { flattenDepth } from "lodash";
import { Feature, MultiPolygon, Polygon } from "geojson";

export default class MapLogic {
  public static fitMapToPipelineBoundingBox(
    pipelineDataGeoJson: IPipelineData
  ): { zoom: number; longitude: number; latitude: number } {
    let result = { ...DEFAULT_MAP_VIEW_PROPS };
    const pipelineDataLength = pipelineDataGeoJson.features.length;
    if (!pipelineDataLength) {
      return result;
    }
    const coordinates = flattenDepth(
      pipelineDataGeoJson.features.map((feature) => {
        if (feature.geometry.type.toLowerCase() === "multilinestring") {
          return flattenDepth(feature.geometry.coordinates, 1);
        } else {
          return feature.geometry.coordinates;
        }
      }),
      1
    ) as unknown as number[][];
    const southWest = new LngLat(coordinates[0][0], coordinates[0][1]);
    const northEast = new LngLat(coordinates[0][0], coordinates[0][1]);
    const bounds = coordinates.reduce(function (bounds, coord: number[]) {
      const lngLat = new LngLat(coord[0], coord[1]);
      return bounds.extend(lngLat);
    }, new LngLatBounds(southWest, northEast));
    result = fitBounds({
      width: window.innerWidth - 200, // 200 is the width of the sidebar in px
      height: window.innerHeight,
      bounds: [
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getEast(), bounds.getNorth()],
      ], // opposite corners specified as [[lon, lat], [lon, lat]]
      padding: 20, // padding in px around the bounds
    });
    return result;
  }

  public static fitMapToEventBoundingBox(coordinates: any): {
    zoom: number;
    longitude: number;
    latitude: number;
  } {
    let result = { ...DEFAULT_MAP_VIEW_PROPS };
    if (coordinates) {
      result.longitude = coordinates[0];
      result.latitude = coordinates[1];
      result.zoom = 9;
    }
    return result;
  }

  public static extractPipelineLastChecked(
    pipelineDataGeoJson: IPipelineData
  ): Moment {
    const descendingLastChecked = pipelineDataGeoJson.features
      .map((feature) => feature.properties.lastUpdate)
      .sort()
      .reverse();
    return descendingLastChecked.length
      ? moment.utc(descendingLastChecked[0])
      : moment.unix(0);
  }

  public static calculatePolygonCenter(
    polygon: Feature<MultiPolygon> | Feature<Polygon>
  ) {
    let coordinates = polygon.geometry.coordinates[0][0];
    let totalPoints = coordinates.length;
    let x = 0;
    let y = 0;

    for (let i = 0; i < totalPoints; i++) {
      x += coordinates[i][0];
      y += coordinates[i][1];
    }

    return [x / totalPoints, y / totalPoints];
  }
}
